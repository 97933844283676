.loader {
    width: 10px;
    height: 80px;
    border-radius: 10px;
    display: block;
    margin: 20px auto;
    position: relative;
    background: currentColor;
    color: #0a6847;
    box-sizing: border-box;
    animation: animloader 0.3s 0.3s linear infinite alternate;
}

.loader::after,
.loader::before {
    content: "";
    width: 10px;
    height: 80px;
    border-radius: 10px;
    background: currentColor;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    box-sizing: border-box;
    animation: animloader 0.3s 0.45s linear infinite alternate;
}
.loader::before {
    left: -20px;
    animation-delay: 0s;
}

@keyframes animloader {
    0% {
        height: 48px;
    }
    100% {
        height: 4px;
    }
}
