/* animation */
.carousel .list .item:nth-child(1) {
    z-index: 1;
}

/* animation text in first item */
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
    transform: translateY(50px);
    filter: blur(20px);
    opacity: 0;
    animation: showContent 0.5s 1s linear 1 forwards;
}
@keyframes showContent {
    to {
        transform: translateY(0px);
        filter: blur(0px);
        opacity: 1;
    }
}
.carousel .list .item:nth-child(1) .content .title {
    animation-delay: 1.2s !important;
}
.carousel .list .item:nth-child(1) .content .topic {
    /* animation-delay: 1.4s !important; */
}
.carousel .list .item:nth-child(1) .content .des {
    animation-delay: 1.6s !important;
}
/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
    animation: showImage 0.5s linear 1 forwards;
}
@keyframes showImage {
    to {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
    overflow: hidden;
    animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.prev .list .item img {
    z-index: 100;
}
@keyframes showThumbnail {
    from {
        width: 0;
        opacity: 0;
    }
}
.carousel.next .thumbnail {
    animation: effectNext 0.5s linear 1 forwards;
}

@keyframes effectNext {
    from {
        transform: translateX(150px);
    }
}

/* running time */
@keyframes runningTime {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}

/* prev click */

.carousel.prev .list .item:nth-child(2) {
    z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
    animation: outFrame 0.5s linear 1 forwards;
    position: absolute;
    bottom: 0;
    left: 0;
}
@keyframes outFrame {
    to {
        background-color: white;
        box-shadow: 10px 10px 10px 0 rgba(0, 0, 0, 0.33);
        width: 250px;
        height: 330px;
        bottom: 50px;
        left: 50%;
        border-radius: 12px;
    }
}

.carousel.prev .thumbnail .item:nth-child(1) {
    overflow: hidden;
    opacity: 0;
    animation: showThumbnail 0.5s linear 1 forwards;
}
.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
    animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
    to {
        transform: translateY(-150px);
        filter: blur(20px);
        opacity: 0;
    }
}
@media screen and (max-width: 678px) {
    .carousel .list .item .content {
        padding-right: 0;
    }
    .carousel .list .item .content .title {
        font-size: 30px;
    }
}
