@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Rustico";
    src: url("../fonts/Rustico-Regular.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Halimun";
    src: url("../fonts/Halimun.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Bold";
    src: url("../fonts/Quicksand_Bold.otf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Bold Oblique";
    src: url("../fonts/Quicksand_Bold_Oblique.otf") format("truetype");
    font-weight: bold;
    font-style: oblique;
}

@font-face {
    font-family: "Quicksand Book";
    src: url("../fonts/Quicksand_Book.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Book Oblique";
    src: url("../fonts/Quicksand_Book_Oblique.otf") format("truetype");
    font-weight: normal;
    font-style: oblique;
}

@font-face {
    font-family: "Quicksand Dash";
    src: url("../fonts/Quicksand_Dash.otf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Light";
    src: url("../fonts/Quicksand_Light.otf") format("truetype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Quicksand Light Oblique";
    src: url("../fonts/Quicksand_Light_Oblique.otf") format("truetype");
    font-weight: 300;
    font-style: oblique;
}

@font-face {
    font-family: "Quicksand Variable";
    src: url("../fonts/Quicksand[wght].ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
    font-family: "Quicksand", sans-serif;
}

.title-clamp-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.title-clamp-5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
}
