/* .swiper-wrapper {
  transform: translate3d(0px, 0, 0) !important;
  justify-content: start;
} */

/* .swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next {
  right: 10px;
}

.swiper-button-prev {
  left: 10px;
} */

@media (min-width: 1024px) {
  .explore-section .swiper-wrapper {
    justify-content: center;
  }
}

